<template>
  <div class="background-img-2 flx flx-d-column">
    <div class="flx j-c-center a-i-center">
      <h2 class="title-dark">Como funciona?</h2>
    </div>
    <div class="text">
      <img src="../assets/img/audio-2.png" alt="imagem" width="80%" />
      <p>Envie o áudio</p>
      <p>Envie sua foto</p>
      <p>Escolha o layout</p>
      <p>Eltere o título</p>
      <p>Adicione seu instagram</p>
      <p>Escolha o formato</p>
      <p>Pronto!</p>
    </div>

    <button class="btn-signing">entre</button>

    <router-link to="/cadastre-se">
      <button class="btn-signup">cadastre-se</button>
    </router-link>
    <!-- <a class="btn-recover">ops, eu esqueci minha senha</a> -->
  </div>
</template>

<style>
</style>

<script>
export default {
  name: "HowWorks",
};
</script>
